import { Manual, ManualItem } from '../../utils/manualUtils'

export interface Metadata {
    FileName: string;
    SubGroupID: string;
    FileExtension: string;
    namespace: string;
    Manufacturer: string;
    ItemGraphicID: string;
    matchedBy: string

}

export interface Match {
    id: string;
    score: number;
    metadata: Metadata;

    
}

export interface Image {
    matches: Match[];
    usage: {
        read_units: number;
    };
}

export const createPdfFileUploadSlice = (set: any, get: any) => ({
    ...createPdfFileUploadStates(set, get),
    ...createPdfFileUploadActions(set, get),
});

export const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
export const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

const createPdfFileUploadStates = (set: any, get: any) => ({
    uploadComplete: false,
    fileMap: {},
    uploadedFiles: [],
    filesUploaded: false,
    extractionFinished:false,
    extractedImages: [],
    specialTools: [],
    manuals : [],

    setUploadComplete: (status: boolean) => set({ uploadComplete: status }),
    setExtractionFinished: (status: boolean) => set({ extractionFinished: status }),
    setFileMap: (fileMap: any) =>
        set((state: any) => ({
            fileMap: 
                {
                    [fileMap.fileName]: fileMap.eTag,
                    ...state.fileMap
                },
                
            
        })),

    setUploadedFiles: (file: File) =>
        set((state: any) => ({
            uploadedFiles: [
                file,
                ...state.uploadedFiles
            ]
        })),
    clearFileMap: () => {
            set({ fileMap: {} });
        },
    
    clearUploadedFiles: () => {
            set({ uploadedFiles: [] });
        },
    setSpecialTools: (tools: string[]) => set({ specialTools: tools }),
    setExtractedImages: (images: Record<string, Image>[]) =>
            set({ extractedImages: images }),
    setManuals: (manuals:Manual[]) => set({ manuals: manuals })
});

const createPdfFileUploadActions = (set: any, get: any) => ({
});