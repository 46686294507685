import { useLoaderData, useNavigate, useLocation } from 'react-router-dom';
import ItemResults from '../ItemResults/ItemResults';
import { useEffect, useState } from 'react';
import { useConfigState, useResultsState } from '../../store';
import ShowProductCategories from '../Config/ShowProductCategories'
import { useTranslation } from 'react-i18next';
import MetadataSelector from './MetadataSelector';
import aws_config from '../../modified_aws-export';
import { DropFile } from '../DropZone/DropZone';
import { getDropZoneResult, pineconeQuery } from '../../api/backend';
import MetadataFilter from './Filter';
import { useRecordEvent } from 'aws-rum-react';
import {getLocalItem, setLocalItem} from '../../store/slices/pdfuploadSlice'


interface ResultPageProps {
}

export const loader = async ({ params }: any) => {
  try {
    const dropfile = await getDropZoneResult(params.uuid);
    return [dropfile, params.single]
  } catch (error) {
    throw error;
  }
}
 

const ResultPage: React.FC<ResultPageProps> = () => {
  const { results, setResults, getResultCount } = useResultsState()
  let [current_file, single] = useLoaderData() as [DropFile,boolean]
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [displayResults, setDisplayResult] = useState<any>([])
  const { top_k, metadataSelector, showProductCategories } = useConfigState()
  const [filter, setFilter] = useState<object>({})
  const [filterResults, setFilterResults] = useState(true)
  const recordEvent = useRecordEvent();
  const location = useLocation();
  const fromImageCanvas = location.state?.fromImageCanvas;
  const params = new URLSearchParams(location.search);
  const openInNewTab = params.get('openInNewTab') === 'true';





  useEffect(() => {
    if (current_file.result && current_file.result['ungrouped']) {
      let display: any = current_file.result['ungrouped']
      if (showProductCategories) {
        display = current_file.result['grouped']
      }
      setDisplayResult(display)
      setFilterResults(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProductCategories])

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      setFilterResults(true);
      (async () => {
        await pineconeQuery(current_file, aws_config.aws_user_files_s3_bucket, "image_similarity_search/" + current_file.id + ".png", Number(top_k), setResults, recordEvent, JSON.stringify({ "$and": [filter, { "namespace": {"$in":["OEM","Single"]}}] }))
        let display: any = current_file.result['ungrouped']
        if (showProductCategories) {
          display = current_file.result['grouped']
        }
        setDisplayResult(display)
        setFilterResults(false)
      })();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (current_file.result && current_file.result['ungrouped']) {
      let display: any = current_file.result['ungrouped']
      if (showProductCategories) {
        display = current_file.result['grouped']
      }
      setDisplayResult(display)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])



  return (
    <div className='card border-0 d-md-block' style={{ width: "90%" }}>
       {(!openInNewTab) && (
      <button className="btn btn-secondary" type="button" onClick={() => (getResultCount() === 1) ? navigate('/') : navigate('/image-matcher/results')}>
        {t("Button.back")}
      </button>
      )}
      <div className='text-center'>
        <img src={current_file.preview} alt='' className='rounded mx-auto d-block' />
        {filterResults ?
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('DropZone.loading')}
          </>
          :
          null
        }

      </div>

      <MetadataFilter setResults={setResults} dropfile={current_file}></MetadataFilter>

      {metadataSelector && current_file.result['ungrouped'] ? <MetadataSelector items={current_file.result['ungrouped']} onChange={setFilter}></MetadataSelector> : null}
      <ShowProductCategories></ShowProductCategories>

      <div className="row" style={{ marginTop: "20px" }}>
        {/* <div className="card-group results"> */}
        {
          displayResults.length !== 0 ?
            displayResults.map((elem: any) => {
              return (
                <div className="col justify-content-center d-flex" key={elem.id}>
                  <ItemResults score={elem.score as number} metadata={elem.metadata} elements={elem.elements} dropfile={current_file} ></ItemResults>
                </div>
              )
            })
            :
            <div className="alert alert-warning justify-content-center d-flex" role="alert" style={{ width: "80%", margin: "auto" }}>
              {t("Filter.resultsNotFound")}
            </div>
        }
      </div>
      {(!openInNewTab) && (
      <button className="btn btn-secondary  mt-2" type="button" onClick={() => (getResultCount() === 1) ? navigate('/') : navigate('/image-matcher/results')}>
        {t("Button.back")}
      </button>
        )}
    </div>
  );
}

export default ResultPage