// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "RumUserMonitoring": {
        "RumMonitorId": "59514667-78d3-41c7-b490-366d3604cae9",
        "RumRegion": "eu-central-1",
        "RumTelemetries": "errors,http"
    },
    "aws_ssm_param_rmi_company_name": "/special-tools/main/rmi-service-company",
    "aws_ssm_param_rmi_secret_name": "/special-tools/main/rmi-service-password",
    "aws_ssm_param_rmi_user_name": "/special-tools/main/rmi-service-username",
    "aws_ssm_param_rmi_type_id": "/special-tools/main/rmi-service-type-id",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:d5342e95-556c-46d7-b1b5-79c2b676b8ca",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://main.d1eeyxn7movq77.amplifyapp.com/,https://main.rmi.imagesimilarity.tecalliance.net/,https://rmi.imagesimilarity.tecalliance.net/",
        "domain": "tecrmi-auth-main.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://main.d1eeyxn7movq77.amplifyapp.com/,https://main.rmi.imagesimilarity.tecalliance.net/,https://rmi.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_suz8OC1SC",
    "aws_user_pools_web_client_id": "63pr217h3elndji7cun89fsuhj",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "TecRMImainFronteoAzureAD55426842",
    "aws_cognito_social_providers": [],
    "aws_user_files_s3_bucket": "tecrmi-main-frontend",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://w3bqwdihjjewpayar3cg3cgwwy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "backend": {
        "region": "eu-north-1",
        "bucket_name": "tecrmi-manuals-main"
    },
    "amplify_appid": "d1eeyxn7movq77",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
